angular.module('sq.jobs.queue.service',
['sq.jobs.tracker.service', 'sq.jobs.values', 'sq.jobs.events.service'])
.factory('queueState', queueStateFactory);

function queueStateFactory(jobTracker, jobEvents,
jobValues, $rootScope, $log) {
  var qS = {};
  var _idChosen = null;
  // TODO: need to either not use these defaults, or make sure
  // that they are non-enumerable, for ng-repeat.
  Object.defineProperty(qS, 'numJobs', {
    get: function getNewResultsCount() {
      numJobs = Object.keys(jobTracker.jobs.all).length;
      if (numJobs > jobValues.maxNotifications) {
        return jobValues.maxNotifications + '+';
      }
      return numJobs;
    },
    enumerable: true,
  });

  Object.defineProperty(qS, 'idChosen', {
    get: function getIDchosen() {
      return _idChosen;
    },
    set: function setIDchosen(val) {
      _idChosen = +val || null;
    },
  });

  Object.defineProperty(qS, 'jobBeingViewed', {
    get: function getJobBeingViewed() {
      if (qS.idChosen) {
        if (jobTracker.jobs.all[qS.idChosen] ) {
          return jobTracker.jobs.all[qS.idChosen];
        }
        $log.warn(qS.idChosen + 
          ' doesn\'t look like a real job public id');
      }
      return null;
    },
  });

  $rootScope.$on('sq.jobs.events.service:' + jobEvents.events.clear,
    function onClear() {
      _idChosen = null;
    }
  );

  return qS;

  /* @ private */
}